.mw__header{
    display:flex;
}
.mw__header-content{
    flex:1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;

}
.mw__header-content h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}
.mw__header-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);
    margin-top: 1.5rem;
}
.mw__header-content__input{
    width:100% ;
    margin: 2rem 0 1rem;
    display: flex;
}

.mw__header-content__input input{
    flex: 2;
    width: 100%;
    min-height: 50px;
    padding: 0 1rem;
    font-family: var(--font-family);
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    border-radius: 5px 0px 0px 5px;
    outline: none;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
   
    color: #fff;
    box-shadow: none;
}
.mw__header-content__input button{
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    background: #FF4820;
   

    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 0px 5px 5px 0px;

    color: #FFFFFF;
}
.mw___header-content__people{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;
}
.mw___header-content__people img{
    width: 181.79px;
    height: 38px;
}
.mw___header-content__people p{
    margin:0 0 0 1rem ;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    text-align: center;
}
.mw__header-image{
    flex: 1;
    display: flex;
    justify-content:center;
    align-items: center;
}
.mw__header-image img{
    width: 100%;
    height: 100%;
}


@media  screen and  (max-width:1070px){
    .mw__header{
        flex-direction: column;
    }
    .mw__header-content{
        margin: 0 0 3rem;
    }
}
@media  screen and  (max-width:650px){
    .mw__header h1{
        font-size: 48px;
        line-height: 60px;
    }
    
    .mw___header-content__people{
        flex-direction: column;
        
    }
    .mw___header-content__people{
        margin: 0;
        
    }
    .mw__header p,
    .mw__header-content__input input,
    .mw__header-content__input button{
        font-size: 16px;
        line-height: 24px;
    }

}
@media  screen and  (max-width:490px){
    .mw__header h1{
        font-size: 36px;
        line-height: 48px;
    }
    .mw__header p{
        font-size: 14px;
        line-height: 24px;
    }
    .mw__header-content__input input,
    .mw__header-content__input button{
        font-size: 12px;
        line-height: 14px;
    }
} 