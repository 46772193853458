.mw__possibilty{
   display: flex;
}

.mw__possibility-image{
   flex: 1;
   display: flex;
   justify-content: flex-start;
   align-items: center;

   margin-right: 2rem;
}
.mw__possibility-image img{
    width: 100%;
    height: 100%;
}
.mw__posisbility-content{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}
.mw__posisbility-content h4{
    width: 271px;
    height: 30px;


    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;


    color: #71E5FF;
}
.mw__posisbility-content h4:last-child{
    /* width: 271px;
   height: 30px; */


   font-family: 'Manrope';
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 30px;


   color: #FF8A71;
}
.mw__posisbility-content h1{
    /* width: 426px;
    height: 90px; */


    background: var(--gradient-text);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;

    margin: 1rem 0;
}
.mw__posisbility-content p{
    /* width: 479px;
    height: 120px; */


    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 1rem ;
/* or 188% */


    color: #81AFDD;
}
@media screen and (max-width: 950px) {
    .mw__possibilty{
      flex-direction:column;
     }
     .mw__posisbility-content h4{
        margin-top: 1rem;
     }
     .mw__posisbility-content p{
        margin-bottom: 0;
     }
     .mw__possibility-image{
        margin: 1rem 0;
     }
     
}


