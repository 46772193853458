@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;


}

*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    list-style: none;
}

body{
    background: var(--color-bg);
}
a{
    color: unset;
    text-decoration: none;
}

.gradient__bg{

    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 4% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 4% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 4% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 4% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 4% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

}
.gradient__text{
    background: var(--gradient-text);
    background-clip: text ;
    color: transparent;
    /* background: linear-gradient(88deg, #ae67fa, #f49867); */
    background-size: 400% 400%;
    -webkit-animation: AnimationName 31s ease infinite;
    -moz-animation: AnimationName 31s ease infinite;
    -o-animation: AnimationName 31s ease infinite;
    animation: AnimationName 31s ease infinite;

}

@-webkit-keyframes AnimationName {
  0%{background-position:0% 53%}
  50%{background-position:100% 48%}
  100%{background-position:0% 53%}
}
@-moz-keyframes AnimationName {
  0%{background-position:0% 53%}
  50%{background-position:100% 48%}
  100%{background-position:0% 53%}
}
@-o-keyframes AnimationName {
  0%{background-position:0% 53%}
  50%{background-position:100% 48%}
  100%{background-position:0% 53%}
}
@keyframes AnimationName {
  0%{background-position:0% 53%}
  50%{background-position:100% 48%}
  100%{background-position:0% 53%}
}
.section__padding{
    padding: 4rem  6rem;
}
.section__margin{
    margin: 4rem 6rem;
}
.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
ss-selector {
  background: linear-gradient(88deg, #ae67fa, #f49867);
 

  -webkit-animation: gradient__text-color 31s ease infinite;
  -moz-animation: gradient__text-color 31s ease infinite;
  animation: gradient__text-color 31s ease infinite;

  
}

 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @media screen and (max-width:700px){
    .section__padding{
        padding:4rem;
    }
    .section__margin{
        margin:4rem;
    }
  }
  @media screen and (max-width:550px){
    .section__padding{
        padding:4rem 2rem;
    }
    .section__margin{
        margin:4rem 2rem;
    }
  }