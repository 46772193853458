.mw__features-container__features{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 1rem;
}
.mw__features-container__features-title{
    flex: 1;
    max-width: 180rem;
    margin-right: 2rem;

}
.mw__features-container__features-title h1{
    font-family:var(--font-family);
    font-weight: 800;
  font-size: 18px;
  line-height: 24px;
    letter-spacing: -0.04em;
    color: #FFFFFF;
}
.mw__features-container__features-title div {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0 0 0 rgba(0,0,0,0.3);
    margin-bottom: 0.35rem;
    

}
.mw__features-container__features-text{
    flex: 2;
    max-width: 390px;
    display: flex;

}
.mw__features-container__features-text p{
    width: 340px;
    height: 92px;
    


    font-family: var(--font-family);
    font-size: 16px;
    line-height: 30px;
    color: #81AFDD;
}
@media screen and (max-width: 750px) {
    .mw__features-container__features-title h1{
        font-size: 14px;
       line-height: 22px;
      
    }
    .mw__features-container__features-title p{
        font-size: 12px;
       line-height: 20px;
    }
    .mw__features-container__features{
        margin: 1rem 0;
    }
    .mw__features-container__features-title div{
        margin-top: 1rem ;
    }

}
