.mw__whatsl{
    display: flex;

    flex-direction: column;
    padding: 2rem;
    overflow-y:hidden;
    
    /* background: var(--color-footer); */

    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 100% 100%, rgba(27, 120, 222, 1) 0%, rgba(15, 66, 121, 1) 23%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 100% 100%, rgba(27, 120, 222, 1) 0%, rgba(15, 66, 121, 1) 23%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 100% 100%, rgba(27, 120, 222, 1) 0%, rgba(15, 66, 121, 1) 23%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 100% 100%, rgba(27, 120, 222, 1) 0%, rgba(15, 66, 121, 1) 23%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 100% 100%, rgba(27, 120, 222, 1) 0%, rgba(15, 66, 121, 1) 23%);

}

.mw__whatsl-features{
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;
    width: 100%;
   

}

.mw__whatsl-features.mw__features-container__features{
    margin: 0rem;
 
    /* min-width: 210px;
    flex: 1; */
   
}
.mw__whatsl-features.mw__features-container__features-text{
   max-width: 730px; 
}
.mw__whatsl-heading{
    display: flex;
    justify-content:space-between;
    align-items: flex-start;
    margin: 4rem 0  2rem ;
   
}
.mw__whatsl-heading h1{
    font-family: var(--font-family);
    max-width: 472px;
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    
}
.mw__whatsl-heading p {
    font-family: var(--font-family);
    max-width: 143px;
    font-weight: 500;
    font-size: 16px; 
    line-height: 30px;
    color: #FF8A71;
    cursor: pointer;
}

.mw__whatsl-container{
   display: flex;
   flex-wrap: wrap;
   margin-top: 2rem ;
   width: 100%;
} 


/* Customizing feature component as per needs */
.mw__whatsl-container .mw__features-container__features {
    flex: 1;
    /* margin: 1rem; */
    min-width: 210px;
    display: unset;
    flex-direction: column;
}

/* Customizing feature component as per needs */
.mw__whatsl-container .mw__features-container__features-text {
    margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
    .mw__whatsl-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .mw__whatsl-heading p {
        margin-top: 1rem;
    }
}
@media screen and (max-width: 1050px){
    .mw__whatsl-heading h1{
        font-size: 28px;
        line-height: 40px;
        margin-top: 2rem;
    }
}
@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .mw__whatsl-features .mw__features-container__features{
        flex-direction: column;
    }

    .mw__whatsl-features .mw__features-container__features-text {
        margin-top: 1rem;
        max-width: 600px;   
    }

    

    .mw__whatsl-heading h1{
        font-size: 22px;
        line-height: 40px;
        margin-top: 5rem;
    }
    .mw__features-container__features-text p{
        max-width: 250px;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .mw__whatsl-container .mw__features-container__features {
        margin: 1rem 0;
        min-width: 100%;
       
    }
    .mw__features-container__features-text p{
        max-width: 190px;
        margin-bottom: 1rem;
        padding-right: 0.5rem;
    }
    
    .mw__whatsl-heading h1{
        font-family: var(--font-family);
        max-width: 472px;
        font-weight: 600;
        font-size: 25px;
        line-height: 20px;
        margin-top: 10rem;
        
    }
  
    
}
     
     